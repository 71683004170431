<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Vendedores - Zonas</h4>
            <div class="small text-muted">Administración de las zonas disponibles</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Zonas">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col md="3">
            <b-form-group>              
              <b-form-input type="text" 
                            size="sm" 
                            placeholder="Ingrese el nombre de la zona" 
                            v-model="filters.name">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">              
            <v-select :options="arr.filters.sellers" v-model="filters.sellers" placeholder="Vendedores" :multiple="true" :select-on-tab="true"></v-select>                          
          </b-col>
          <b-col md="3">              
            <v-select :options="arr.filters.withCustomer" v-model="filters.withCustomer" placeholder="Estado Clientes" :multiple="false" :select-on-tab="true"></v-select>                
          </b-col>
          <b-col sm="3">
            <b-button variant="outline-dark" @click="load()">
              <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
              Filtrar
            </b-button>
          </b-col>              
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    ref="table"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"         
                    :busy="table.isBusy"                          
                    v-if="table.items.length || table.isBusy">                       
                
                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>

                <template v-slot:row-details="row">
                  <b-card>
                    <b-row>
                      <b-col md="6">
                        <b-row>
                          <b-col md="6">
                            <div class="sellers-zone-titulo-subline mb-2">
                              <b>Zona:</b> {{row.item.name}}
                            </div>
                          </b-col>
                          <b-col md="6">
                            <b-button @click="addSub(row.item)" type="button" variant="secondary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Clientes">
                              <i class="fa fa-plus"></i> Agregar
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="12">
                            <b-table class="mb-0"
                                    responsive="sm"
                                    head-variant="dark"
                                    :hover="true"
                                    :small="true"
                                    :fixed="false"
                                    :items="tableSub.items"
                                    :fields="tableSub.fields"                            
                                    v-if="tableSub.items.length">   
                              
                              <template v-slot:cell(id)="data">
                                  <b>{{data.item.customer.id}}</b>
                              </template>

                              <template v-slot:cell(code)="data">                                
                                <b>{{data.item.customer.code}}</b>                                
                              </template>

                              <template v-slot:cell(customers_id)="data">
                                <b-avatar :src="data.item.customer.image"
                                          v-if="data.item.customer.image">
                                </b-avatar>
                                <b-avatar v-else    
                                          icon="building"              
                                          variant="dark">
                                </b-avatar>     
                                &nbsp;<b>{{data.item.customer.name}}</b>
                                <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                
                              </template>  

                              <template v-slot:cell(f_action)="data">
                                <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                  <b-dropdown-item @click="removeSub(data.item)">
                                    <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                  </b-dropdown-item>
                                </b-dropdown>
                              </template>

                            </b-table>
                            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>
                                
                <template v-slot:cell(observations)="data">                           
                  <div class="sellers-zone-table-observations" v-if="data.item.customers.length">
                    <b>Tiene ({{data.item.customers.length}}) clientes</b>
                  </div>
                  <div v-else class="text-warning">
                    <b>No tiene clientes</b>
                  </div>                  
                </template>                

                <template v-slot:cell(id)="data">
                  <b>{{data.item.id}}</b>
                </template>

                <template v-slot:cell(sellers_id)="data">
                  <b-avatar :src="data.item.seller.staff.image"
                            v-if="data.item.seller.staff.image">
                  </b-avatar>
                  <b-avatar v-else                                             
                            variant="dark">
                  </b-avatar>     
                  &nbsp;<b>{{data.item.seller.name}}</b>
                </template>               

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="load()" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>
          </b-col>                 

          <b-col md="12">
            <FindObject type="sellers" 
                        @select-object="loadSellers($event)" 
                        :valueID="crud.form.sellers_id"/>
          </b-col>
        </b-row>        

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD SUB -->
      <b-modal v-model="modal.formSub.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formSub.title}}
        </div>

        <b-row>
          <b-col md="12">
            <FindObject type="sellersZone" 
                        @select-object="loadSellersZone($event)" 
                        :valueID="crud.formSub.zone_id"/>
          </b-col>              
          <b-col md="12">
            <FindObject render="search"
                        type="customers" 
                        @select-object="loadSellersCustomers($event)" 
                        :valueID="crud.formSub.customers_id"
                        :where="conditionCustomersActive"/>
          </b-col>     
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSub.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveSub()">Guardar</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'
  import Param from '@/config/parameters'
  
  export default {
    components: {
      FindObject
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.VENDEDORES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudZones',
          elements: {}
        }, 
        parameters: {
          codeCustomer: Helper.hasParametersAccess(Param.P2)
        },        
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:'align-middle'},
            {key: 'name', label: 'Nombre', class:'align-middle'},
            {key: 'sellers_id', label: 'Vendedor', class:'align-middle'},
            {key: 'observations', label: 'Observaciones', class:'align-middle'},
            {key: 'f_action', label:'', class:'align-middle'},
          ],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,          
        },
        tableSub : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },        
        crud: {
          form: {
            id: 0,
            name: '',            
            sellers_id: 0,
            sellers: null,              
          }, 
          formSub: {
            id: 0,
            customers_id: 0,
            customers: null,  
            zone_id: 0,
            zone: null,                        
          },                    
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formSub: {
            active: false,
            title: ''
          },          
        },
        arr: {
          zones: [],    
          filters: {
            sellers: [],      
            withCustomer: [
              {code:true, label: 'Con Clientes'},
              {code:false, label: 'Sin Clientes'},                   
            ],  
          }          
        },  
        filters: {
          name: '',
          sellers: 0,
          withCustomer: {code:true, label: 'Con Clientes'},
        }              
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.filterLoadSellers()
      this.load()
      this.setFieldTableCustomer()
    },
    computed: {
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      }
    }, 
    methods: {
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {      
        this.arr.zones.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.load()
      },       
      load() {
        this.table.isBusy = true
        var result = serviceAPI.filtrarZona(this.filters, this.table.currentPage)

        result.then((response) => {
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data        
          this.arr.zones = data.data          

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true       
            
            var result1 = serviceAPI.mostrarClienteZona(this.table.items[this.table.rowSelected].id)
            result1.then((response1) => {
              var data1 = response1.data              

              if(data1) {
                this.tableSub.items = data1
              }
            })
          }  

          if(this.$refs.table) {                        
            this.$refs.table.$forceUpdate()
          }               

          this.table.isBusy = false  
        })
        .catch(error => {
          this.table.isBusy = false  
          this.$awn.alert(Error.showError(error))
        });        
      },
      setFieldTableCustomer() {
        if(this.parameters.codeCustomer) {
          this.tableSub.fields.push({key: 'code', label: 'Código', sortable: true, class:"align-middle", width:"50px"})
        } else {
          this.tableSub.fields.push({key: 'id', label: 'ID', sortable: true, class:"align-middle", width:"50px"})
        }
        
        this.tableSub.fields.push({key: 'customers_id', label: 'Cliente', class:"align-middle", width:"200px"})
        this.tableSub.fields.push({key: 'f_action', label: '', class:"align-middle", width:"100px"})
      },

      add() {
        this.crud.form.id = 0
        this.crud.form.name = '' 
        this.crud.form.sellers_id = 0
        this.modal.form.title = "Nueva Zona"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name           
        this.crud.form.sellers_id = item.sellers_id           
        this.modal.form.title = "Editar Zona"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Znona',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarZona(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {                        
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();
        if (this.crud.form.id) {
          var result = serviceAPI.editarZona(this.crud.form);
        } else {
          var result = serviceAPI.agregarZona(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      addSub(item) {
        this.crud.formSub.id = 0
        this.crud.formSub.customers_id = 0
        this.crud.formSub.zone_id = item.id                 

        this.modal.formSub.title = "Asignar Clientes"
        this.modal.formSub.active = true
      },
      removeSub(item) {        
        this.crud.formSub.id = item.id
        this.crud.formSub.customers = item.customer

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.formSub.customers.id + ') - '+ this.crud.formSub.customers.name + '?', {
          title: 'Quitar Cliente',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarZonaCustomers(this.crud.formSub.id);

            result.then((response) => {      
              this.modal.formSub.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      saveSub() {
        let loader = this.$loading.show();
        if (this.crud.formSub.id) {
          var result = serviceAPI.editarZonaCustomers(this.crud.formSub);
        } else {
          var result = serviceAPI.agregarZonaCustomers(this.crud.formSub);
        }

        result.then((response) => {
          this.modal.formSub.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      loadSellers (object) {
        if(object){
          this.crud.form.sellers = object
          this.crud.form.sellers_id = object.id
        } else {
          this.crud.form.sellers = null
          this.crud.form.sellers_id = 0
        }
      },       
      loadSellersCustomers (object) {
        if(object){
          this.crud.formSub.customers = object
          this.crud.formSub.customers_id = object.id
        } else {
          this.crud.formSub.customers = null
          this.crud.formSub.customers_id = 0
        }
      }, 
      loadSellersZone (object) {
        if(object){
          this.crud.formSub.zone = object
          this.crud.formSub.zone_id = object.id
        } else {
          this.crud.formSub.zone = null
          this.crud.formSub.zone_id = 0
        }
      },   
      
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedor()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.sellers = []
          data.forEach(element => {                            
            this.arr.filters.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      },   
    }    
  }
</script>
<style>
  .sellers-zone-titulo-subline {
    font-size: 17px;
  }
  .sellers-zone-table-observations {
    color: darkred;
  }
</style>